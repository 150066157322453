<template>
  <v-card tile :elevation="0" class="crud-box list-ordersyncs">
    <h1 class="mx-4">Manage Ordersyncs</h1>
    <v-layout wrap class="px-4 pt-4">
      <div style="overflow: visible">
<!--        <v-btn color="success" class="mr-2" @click="onClickNew">-->
<!--          <v-icon>mdi-plus</v-icon>-->
<!--          New-->
<!--        </v-btn>-->
<!--        <v-btn color="primary" outlined class="mr-2" @click="onClickImport">-->
<!--          <v-icon>mdi-database-import</v-icon>-->
<!--          Import-->
<!--        </v-btn>-->
<!--        <v-btn color="primary" outlined class="mr-2" @click="onClickExport">-->
<!--          <v-icon>mdi-database-export</v-icon>-->
<!--          Export-->
<!--        </v-btn>-->
<!--        <v-btn color="error" v-if="pickedItems.length > 0" class="mr-2" @click="onClickDeleteAll">-->
<!--          <v-icon>mdi-delete</v-icon>-->
<!--          Delete Items-->
<!--        </v-btn>-->
            <v-btn color="primary" v-if="pickedItems.length > 0" class="mr-2" @click="onClickBatchEdit">
              <v-icon>mdi-plus</v-icon>
              Batch Edit
            </v-btn>
      </div>
      <!---->
      <v-spacer></v-spacer>
      <v-layout wrap style="max-width: calc(100% - 250px);">
</v-layout>
<!--      <v-btn color="primary" @click="doSearch">-->
<!--        <v-icon>mdi-magnify</v-icon>-->
<!--        Search-->
<!--      </v-btn>-->
    </v-layout>
<v-layout wrap class="px-4 pt-4">
      <v-layout wrap class="search-fields search-fields-page">
          <v-text-field dense outlined @keyup.enter="doSearch"
                        class="mr-2" label="Search Customer"
                        v-if="UserRole === CONSTANTS.ROLES.SUPER_ADMINISTRATOR"
                        v-model="searchFields['customer.email'].value"/>
          <v-text-field dense outlined @keyup.enter="doSearch" class="mr-2"
                        label="Search Order Name"
                        v-model="searchFields.name.value"/>
          <v-autocomplete dense outlined @keyup.enter="doSearch" class="mr-2"
                          label="Search Status"
                          :items="listOrderStatus" v-model="searchFields.status.value"/>
          <v-autocomplete dense outlined @keyup.enter="doSearch" class="mr-2"
                          label="Receiver country" style="width: 175px" item-text="name"
                          :items="listCountry" v-model="searchFields.receiver_country_id.value" item-value="id"/>
          <v-spacer style="flex-grow: 100000000000000 !important;"></v-spacer>
      </v-layout>
    <v-btn color="primary" @click="doSearch">
        <v-icon>mdi-magnify</v-icon>
        Search
    </v-btn>
</v-layout>

    <!--card content-->
    <v-card tile :elevation="1" :disabled="tblLoading" outlined class="block-tbl mx-4">
      <v-data-table :headers="tblHeader" :items="listItems" :server-items-length="tblPagination.total" :loading="tblLoading"
                    :footer-props="tblConfig.footerProps" @dblclick:row="onClickRow" :options.sync="tblOptions"
                    :loading-text="tblConfig.text.loading" v-model="pickedItems" :show-select="tblConfig.showSelect">
        <template v-slot:no-data>
          {{ tblConfig.text.noItem }}
        </template>

        <template v-slot:item.no="{item}">
          {{ getIndexOfItem(item) }}
        </template>
        <template v-slot:item.receiver_country_id="{item}">
          {{ item.receiver_country_id ? countryById(item.receiver_country_id).name : '' }}
        </template>
        <template v-slot:item.service_id="{item}">
          {{ item.service_id ? serviceGetName(item.service_id) : '' }}
        </template>
        <template v-slot:item.price="{item}">
          <template v-if="item.price && item.price != ''">
            <money-format :value="parseFloat(item.price)" style="display: inline-block;"
                          :hide-subunits=true :locale="'vi'" :currency-code="'VND'"></money-format>
          </template>
          <template v-else>
          </template>
        </template>
        <template v-slot:item.customer_email="{item}">
          {{ item.customer && item.customer.email ? item.customer.email : '' }}
        </template>

        <template v-slot:item.actions="props">
<!--          <v-icon small @click="onClickDetail(props)" class="mr-2">mdi-view-list</v-icon>-->
          <v-icon small @click="onClickEdit(props)" class="mr-2">mdi-pencil</v-icon>
<!--          <v-icon small @click="onClickDelete(props)">mdi-delete</v-icon>-->
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialog.create" :width="800">
      <CreateItem :onCreated="onCreated" :onCancel="()=>{dialog.create=false}"></CreateItem>
    </v-dialog>
    <v-dialog v-model="dialog.detail" :width="'60%'">
      <DetailItem :selectedItem="selectedItem" :onClose="()=>{dialog.detail=false}"></DetailItem>
    </v-dialog>
    <v-dialog v-model="dialog.update" :width="'80%'">
      <UpdateItem :pickedItems="pickedItems" :isBatchEdit="isBatchEdit" :onUpdated="onUpdated" :selectedItem="selectedItem" :onCancel="()=>{dialog.update=false}"></UpdateItem>
    </v-dialog>
    <v-dialog v-model="dialog.delete" :width="400">
      <DeleteItem :idsToDelete="idsToDelete" :onDeleted="onDeleted" :onCancel="()=>{dialog.delete=false}"></DeleteItem>
    </v-dialog>
    <v-dialog v-model="dialog.import" :width="400">
      <ImportItem :onImported="onImported" :onCancel="()=>{dialog.import=false}"></ImportItem>
    </v-dialog>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import CreateItem from "./Create";
import DetailItem from "./Detail";
import UpdateItem from "./Update";
import DeleteItem from "./Delete";
import ImportItem from "./Import";
import indexMixin from './indexMixin';
import MoneyFormat from "vue-money-format";
import moment from 'moment'
import CONSTANTS from '@/config/constants'

export default {
  name: 'ManageOrdersyncs',
  components: {CreateItem, DetailItem, UpdateItem, DeleteItem, ImportItem, MoneyFormat},
  mixins: [indexMixin],
  data() {
    return {
      searchFields: {
          'customer.email': {
              value: '',
              type: 'like',
              display: true,
              display_name: 'Customer Email',
              display_role: [CONSTANTS.ROLES.SUPER_ADMINISTRATOR, CONSTANTS.ROLES.RECEIVER, CONSTANTS.ROLES.PACKAGER]
          },
          name: {
              value: '',
              type: 'like',
              display: false,
              display_name: 'Order Name',
          },
          status: {
              value: '',
              type: '=',
              display: true,
              display_name: 'Order Status',
          },
          receiver_country_id: {
              value: '',
              type: '=',
              display: true,
              display_name: 'Receiver Country',
          },
      },
      searchWith: 'orderSyncItems;customer',
      searchWithCount: '',
      tblHeader: [
        {
          text: 'No',
          value: 'no',
          sortable: false,
          groupable: false,
          width: '55px',
          align: 'start',
        },
        //
        // {
        //   text: 'CustomerId',
        //   value: 'customer_id',
        // },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Code',
          value: 'code',
        },
        {
          text: 'Note',
          value: 'note',
        },
        // {
        //   text: 'Status',
        //   value: 'status',
        // },
        {
          text: 'Service',
          value: 'service_id',
        },
        {
          text: 'Price',
          value: 'price',
        },
        {
          text: 'Return Address',
          value: 'return_address',
        },
        // {
        //   text: 'IncomeShipmentId',
        //   value: 'income_shipment_id',
        // },
        // {
        //   text: 'IncomeShipmentCode',
        //   value: 'income_shipment_code',
        // },
        // {
        //   text: 'RegisterTrackingStatus',
        //   value: 'register_tracking_status',
        // },
        // {
        //   text: 'BuyTrackingStatus',
        //   value: 'buy_tracking_status',
        // },
        {
          text: 'Receiver Name',
          value: 'receiver_name',
        },
        {
          text: 'Receiver Phone',
          value: 'receiver_phone',
        },
        {
          text: 'Receiver Address',
          value: 'receiver_address',
        },
        {
          text: 'Receiver City',
          value: 'receiver_city',
        },
        {
          text: 'Receiver Post Code',
          value: 'receiver_post_code',
        },
        {
          text: 'Receiver State',
          value: 'receiver_state',
        },
        {
          text: 'Receiver Country',
          value: 'receiver_country_id',
        },
        {
          text: 'Receiver Note',
          value: 'receiver_note',
        },
        {
          text: 'Customer email',
          value: 'customer_email',
        },

        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          groupable: false,
          width: '111px',
          align: 'center',
        }
      ],
    }
  },
  computed: {
    ...mapGetters({
      items: 'ordersync/all',
      tblPagination: 'ordersync/pagination',
      countryById: 'country/getById',
      serviceGetName: 'service/serviceName',
      listOrderStatus: 'ordersync/listOrderStatus',
      countries: 'country/all',
    }),
      listCountry() {
          return [{id: '', name: 'None'}, ...this.countries];
      },
  },
  methods: {
    ...mapActions({
      getListItems: 'ordersync/get',
      exportListItems: 'ordersync/export',
    }),
  }
}
</script>

<template>
  <v-card class="mx-auto pa-5">
    <v-card-title class="mx-0 px-0">Create New Ordersync</v-card-title>
    <v-row>
      <v-col cols="6"><v-text-field dense outlined label="CustomerId" v-model="item.customer_id"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="BigContainerId" v-model="item.big_container_id"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Name" v-model="item.name"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Code" v-model="item.code"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Note" v-model="item.note"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Status" v-model="item.status"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="ServiceId" v-model="item.service_id"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Price" v-model="item.price"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="ReturnAddress" v-model="item.return_address"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="IncomeShipmentId" v-model="item.income_shipment_id"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="IncomeShipmentCode" v-model="item.income_shipment_code"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="RegisterTrackingStatus" v-model="item.register_tracking_status"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="BuyTrackingStatus" v-model="item.buy_tracking_status"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="ReceiverName" v-model="item.receiver_name"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="ReceiverPhone" v-model="item.receiver_phone"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="ReceiverAddress" v-model="item.receiver_address"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="ReceiverCity" v-model="item.receiver_city"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="ReceiverPostCode" v-model="item.receiver_post_code"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="ReceiverState" v-model="item.receiver_state"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="ReceiverCountryId" v-model="item.receiver_country_id"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="ReceiverNote" v-model="item.receiver_note"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="EmployeeNote" v-model="item.employee_note"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="DepartedDate" v-model="item.departed_date"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="DeliveredDate" v-model="item.delivered_date"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="TaxCode" v-model="item.tax_code"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="PackageName" v-model="item.package_name"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="TrackingNumber" v-model="item.tracking_number"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Carrier" v-model="item.carrier"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="DeliveryCode" v-model="item.delivery_code"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="OrderType" v-model="item.order_type"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="WarehouseId" v-model="item.warehouse_id"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Weight" v-model="item.weight"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Length" v-model="item.length"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Width" v-model="item.width"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Height" v-model="item.height"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="RawData" v-model="item.raw_data"/></v-col>
      <v-col cols="6"><v-text-field dense outlined label="Source" v-model="item.source"/></v-col>
</v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="processAdd">Create</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'CreateOrdersync',
  props: ['onCreated', 'onCancel'],
  data: () => ({
    item: {}
  }),
  computed: {
    ...mapGetters({
    }),
  },
  methods: {
    ...mapActions({
      createItem: 'ordersync/create'
    }),
    processAdd() {
      this.createItem(this.item).then(() => {
        this.onCreated();
      });
    }
  }
}
</script>
